import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_2 = {
  id: "kt_aside_toggle",
  class: "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
  "data-kt-toggle": "true",
  "data-kt-toggle-state": "active",
  "data-kt-toggle-target": "body",
  "data-kt-toggle-name": "aside-minimize"
}
const _hoisted_3 = { class: "svg-icon svg-icon-1 rotate-180" }
const _hoisted_4 = { class: "aside-menu flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside aside-hoverable", [
      _ctx.asideTheme === 'light' && 'aside-light',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-white fw-boldest fs-2" }, "SpyLive360", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr080.svg" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_KTMenu)
    ])
  ], 2))
}