import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "header-menu align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
}
const _hoisted_2 = {
  class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item me-lg-1"
}
const _hoisted_4 = { class: "menu-title" }
const _hoisted_5 = {
  key: 1,
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  class: "menu-item menu-lg-down-accordion me-lg-1"
}
const _hoisted_6 = { class: "menu-title" }
const _hoisted_7 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px" }
const _hoisted_8 = {
  key: 0,
  "data-kt-menu-trigger": "{default:'click', lg: 'hover'}",
  "data-kt-menu-placement": "right-start",
  class: "menu-item menu-lg-down-accordion"
}
const _hoisted_9 = { class: "menu-icon" }
const _hoisted_10 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_11 = { class: "menu-title" }
const _hoisted_12 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px" }
const _hoisted_13 = {
  key: 0,
  "data-kt-menu-trigger": "{default:'click', lg: 'hover'}",
  "data-kt-menu-placement": "right-start",
  class: "menu-item menu-lg-down-accordion"
}
const _hoisted_14 = { class: "menu-title" }
const _hoisted_15 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px" }
const _hoisted_16 = { class: "menu-title" }
const _hoisted_17 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_18 = { class: "menu-title" }
const _hoisted_19 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_20 = { class: "menu-icon" }
const _hoisted_21 = { class: "svg-icon svg-icon-2" }
const _hoisted_22 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MainMenuConfig, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (!item.heading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.pages, (menuItem, j) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                  (menuItem.heading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_router_link, {
                          class: "menu-link",
                          to: menuItem.route,
                          "active-class": "active"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate(menuItem.heading)), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            : _createCommentVNode("", true),
          (item.heading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", {
                  class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(item.route) }])
                }, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate(item.heading)), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-arrow d-lg-none" }, null, -1))
                ], 2),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (menuItem, j) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                      (menuItem.sectionTitle)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(menuItem.route) }])
                            }, [
                              _createElementVNode("span", _hoisted_9, [
                                (_ctx.headerMenuIcons === 'font')
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                    }, null, 2))
                                  : _createCommentVNode("", true),
                                (_ctx.headerMenuIcons === 'svg')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                      _createVNode(_component_inline_svg, {
                                        src: menuItem.svgIcon
                                      }, null, 8, ["src"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translate(menuItem.sectionTitle)), 1),
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                            ], 2),
                            _createElementVNode("div", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.sub, (menuItem1, k) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                  (menuItem1.sectionTitle)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createElementVNode("span", {
                                          class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(menuItem1.route) }])
                                        }, [
                                          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-bullet" }, [
                                            _createElementVNode("span", { class: "bullet bullet-dot" })
                                          ], -1)),
                                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.translate(menuItem1.sectionTitle)), 1),
                                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                                        ], 2),
                                        _createElementVNode("div", _hoisted_15, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem1.sub, (menuItem2, l) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: l,
                                              class: "menu-item"
                                            }, [
                                              _createVNode(_component_router_link, {
                                                class: "menu-link py-3",
                                                "active-class": "active",
                                                to: menuItem2.route
                                              }, {
                                                default: _withCtx(() => [
                                                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-bullet" }, [
                                                    _createElementVNode("span", { class: "bullet bullet-dot" })
                                                  ], -1)),
                                                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.translate(menuItem2.heading)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["to"])
                                            ]))
                                          }), 128))
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (menuItem1.heading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _createVNode(_component_router_link, {
                                          class: "menu-link",
                                          "active-class": "active",
                                          to: menuItem1.route
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "menu-bullet" }, [
                                              _createElementVNode("span", { class: "bullet bullet-dot" })
                                            ], -1)),
                                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.translate(menuItem1.heading)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["to"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (menuItem.heading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createVNode(_component_router_link, {
                              class: "menu-link",
                              "active-class": "active",
                              to: menuItem.route
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_20, [
                                  _createElementVNode("span", _hoisted_21, [
                                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/layouts/lay009.svg" })
                                  ])
                                ]),
                                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.translate(menuItem.heading)), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}