const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        fontIcon: "fad fa-columns",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Clientes",
        route: "/apps/customers/customers-listing",
        fontIcon: "fad fa-users",
      },
      {
        heading: "Assinaturas",
        route: "/apps/subscriptions/subscriptions-listing",
        fontIcon: "fad fa-draw-circle",
      },
      {
        heading: "Vendas",
        route: "/apps/orders/orders-listing",
        fontIcon: "fad fa-dollar",
      },
      {
        heading: "Afiliados",
        route: "/apps/affiliates/affiliates-listing",
        fontIcon: "fad fa-user",
      },
    ],
  },
];

export default DocMenuConfig;
